import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

      <div className="page_header">
         <h2 className="sub_title">20 X 20 / 上半場</h2>
         <h1 className="main_title">主持人評論與講者回應</h1>
      </div>


      <article className="page_content blog_single_post_content">

        <section className="row_area_s">
          <div className="column_5">
              <div className="context">
                <h4>主持人評論</h4>
                <p>龔書章認為公共藝術是沒有框架的，藝術就是因為沒有界線才能打開清晰的視野，社宅計劃本身也可以被視為一個公共藝術。</p>
                <p>龔書章分享他曾參與的審查經歷，其中常遇到較小型的公共藝術案子，如警察局、消防局、衛生局、養老院、醫療機構等，他們因自身背景，直覺無法做公共藝術，而將預算納入台北市公共藝術基金當中。然而龔書章認為，如上述社會性強的機構，應利用這個機會重新定義自身的公共性，透過公共藝術服務、幫助他們的特定族群。</p>
                <p>他舉例２０１７台北世大運，重新定義世大運品牌形象的核心小組就是由２０１６年對熊讚批評聲浪最大的幾位意見者組成，而２０１７最後的成果是非常成功的。今天的講者都是非傳統公共藝術領域的工作者，龔書章認為上半場五位講者皆對於公共藝術的期望傳達出強烈的訊息。社宅公共藝術是一個大型的計畫，透過對公共藝術有多元想像、有熱情、有期待不同領域人才的加入，專業可以被重新定義，讓我們有不一樣的公共藝術。</p>
                <p className="onset_right_1">龔書章認為沈伯丞提出具有當代性的內容，公共藝術需要回應生態、氣候、環境，甚至可以表演藝術來回應生活，這些都是還沒被社宅正視過的議題。</p>
                <p className="onset_right_1">賴映如提出如何建構社宅的植物園生態，回應到沈伯丞結晶化的概念，透過藝術轉譯讓生態能被感受，社宅本來就有Gareden的規劃，但需在定義社會住宅空間時就釋放出參與機會。</p>
                <p className="onset_right_1">鄭鴻旗對Maker Space跨年齡教育學習系統的發想可以是將來社宅任務進駐或是特定program，然而經營者如何永續經營這件事有待再討論。</p>
                <p className="onset_right_1">林長叡提到Anish Kapoor在芝加哥的裝置，將公共藝術融入環境生活，建立起人與環境的感知溝通橋樑，龔書章希望林長叡再多說明過往經驗中，如何把感知放入生活。</p>
                <p className="onset_right_1">彭永翔針對公共藝術處理社區或特殊族群的討論已有人推廣，台北市興隆一二區的社宅也有許多特殊族群，目前由社會局處理一線問題，但第二線如何承接處理？像彭永翔分享的Assemble案例來啟動社宅公共藝術是一種可能。</p>


                <h4>講者回應</h4>
                <h6>鄭鴻旗：</h6>
                <p className="onset_right_1">經營對於這樣的空間非常重要的一件事。他提出兩點，首先空間提供的技術必須貼近民眾的生活，而非僅只介紹科技潮流；再者，Maker Space常常在申請政府補助落入需要呈現結果的循環，離初衷越來越遠。</p>
                <p className="onset_right_1">目前透過提醒參與Maker Space的人，空間帶給他們什麼學習與助益，期望參與的人樂捐。共創的啟動真的很困難，每周空間的主持人都要想主題吸引人參與。某年遇到退休人員 開始做菜給大家吃，成為吸引人過來的原因，這也是經營社群的非常好的誘因。</p>
                <h6>林長叡：</h6>
                <p className="onset_right_1">舉改造社區生活空間的一次經驗──帶藝術家進入社區一起整理房子，與社區居民解釋藝術家想做什麼。透過進入民眾的環境、狀況，實現真的共創。在這樣的過程中，雙方都會成長，居民開始動身參與改造活動，藝術家也得到資訊，創作出連結人文、歷史、環境，符合社區精神特質的作品。</p>
              </div>
          </div>
        </section>

      </article>

        <div className="page_footer">
          <Link to="/blog/post_1_7" className="next_page">
            <div className="next_title">黃姍姍</div>
            <div className="next_owner">20 X 20 / 下半場</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
